@import "../../assets/styles/variables";

.deck-tooltip {
  font-size: 1.4rem;

  h1 {
    color: $jacarta;
  }
}

#new-deck-container {
  width: 100%;
  height: 100vh;
  content: '';
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.6rem;

  .new-deck-inner {
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-image: url("../../assets/images/interface/bg-frame-wide.png");
    padding: 2rem 2.5rem 4.4rem;
    max-width: 133rem;
    max-height: 76.8rem;
    position: relative;

    .nav-btn {
      width: 6.4rem;
      height: 6.4rem;
      background-size: 100% 100%;
      background-repeat: no-repeat;
      cursor: pointer;
      border: none;
      outline: none;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      z-index: 5;

      &.prev {
        transform: translateY(-50%) scaleX(-1);
        left: 0;
      }

      &.next {
        right: 0;
      }
    }

    .new-deck {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
      padding: 4rem 6.4rem 6.4rem 7.2rem;
      position: relative;

      .deck-name {
        font-size: 4.8rem;
        line-height: 5.2rem;
        color: $coffee;
        font-family: $hotelTransylvania;
        font-weight: 700;
        min-width: 10ch;
        background: transparent;
        outline: none;
        border: .1rem dotted transparent;
        transition: border-color .1s ease-in-out;

        &:focus {
          border-color: $coffee;
        }
      }

      .collection {
        margin-top: 1rem;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
        grid-column-gap: 2.4rem;
        grid-row-gap: 2.4rem;

        .card {
          min-width: 16.8rem;
          max-width: 16.8rem;
          min-height: 24rem;
        }

      }

      .deck-footer {
        display: flex;
        position: absolute;
        bottom: -1.8rem;
        align-items: center;
        width: 100%;
        max-width: 114rem;
        justify-content: space-between;
      }
    }

    .hand, .empty-hand {
      position: absolute;
      display: flex;
      left: 50%;
      transform: translateX(-50%);
      bottom: -16rem;
      width: 100%;
      max-width: 70.6rem;
      height: 100%;
      max-height: 35rem;

      .hand-card,
      .empty-hand-card {
        height: 24rem;
        width: 16.8rem;
        max-width: 16.8rem;
        border-radius: 2rem;
        box-sizing: border-box;
        position: absolute;
        top: 1rem;
        left: 50%;

        &.card-1 {
          transform: scale(1.05) translateX(calc(-50% - 20rem)) rotate(-10deg);
          z-index: 1;
          top: 3rem;
        }

        &.card-2 {
          transform: scale(1.05) translateX(calc(-50% - 10rem)) rotate(-5deg);
          z-index: 2;
          top: 1.5rem;
        }

        &.card-3 {
          transform: scale(1.05) translateX(-50%);
          z-index: 3;
        }

        &.card-4 {
          transform: scale(1.05) translateX(calc(-50% + 10rem)) rotate(5deg);
          z-index: 4;
          top: 1.5rem;
        }

        &.card-5 {
          transform: scale(1.05) translateX(calc(-50% + 20rem)) rotate(10deg);
          z-index: 5;
          top: 3rem;
        }
      }

      .empty-hand-card {
        box-shadow: 0 0 5rem 0 $bossanova;
      }

      .hand-card {
        cursor: pointer;
      }

      .hide-empty-card {
        visibility: hidden;
      }
    }

    //.empty-hand {
    //  z-index: 1;
    //}
  }
}
