@import "../assets/styles/variables";
@import "../assets/styles/mixins";

.card {
  display: flex;
  flex-direction: column;
  max-width: 16.8rem;
  min-width: 16.8rem;
  min-height: 24rem;
  width: 100%;
  height: 100%;
  border-radius: 2rem;
  text-align: center;
  font-size: 2rem;
  font-weight: normal;
  font-family: $condensed;
  position: relative;
  z-index: 0;

  @include minDesktopLargeHeight {
    max-width: 15.8rem;
    min-width: 15.8rem;
    min-height: 22.41rem;
  }

  &:last-of-type {
    margin-right: 0;
  }

  &.with-hover {
    cursor: pointer;

    &:before {
      position: absolute;
      border-radius: 1.6rem;
      inset: 0;
      background: transparent;
      content: '';
      z-index: -1;
      transition: all .2s ease-in-out;
    }

    &:hover:not(.current) {
      &:before {
        inset: -.6rem;
        background: $russianViolet;
        filter: blur(3rem);
        opacity: .7;
      }
    }
  }

  &.draggable {
    cursor: grab;
    transform: translate3d(0, 0, 0);
  }

  &.current {
    &:before {
      inset: -.6rem;
      background: $russianViolet;
      filter: blur(3rem);
      opacity: .7;
    }
  }

  &.dragging {
    opacity: 0;
  }

  &.arena {
    flex-direction: column;
    font-size: 4.8rem;
    max-width: 16rem;
    display: flex;
    margin: 0;

    img {
      margin-bottom: 0.3em;
    }
  }

  .background {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    top: 0;
  }

  .image {
    position: absolute;
    width: 14rem;
    z-index: -1;
    right: .7rem;
    top: 1rem;

    img {
      width: 100%;
    }

  }

  .statistics {
    align-items: flex-start;
    flex-direction: column;
    font-size: 1.4rem;
    font-weight: bold;
    position: absolute;
    display: flex;
    bottom: 0;
    right: 0;
    left: 0;
    top: 0;

    .score {
      font-family: $hotelTransylvania;
      justify-content: center;
      align-items: center;
      color: darken($romanCoffee, 10%);
      position: absolute;
      display: flex;
      height: 13.5%;
      width: 20%;
      left: 0;
      top: 0;
    }

    .title {
      font-family: $hotelTransylvania;
      letter-spacing: .1rem;
      position: absolute;
      font-size: 1.4rem;
      color: $bossanova;
      text-align: center;
      right: 15%;
      left: 17%;
      top: 51%;
    }

    .extended-props {
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: 1fr 1fr;
      flex-direction: column;
      grid-row-gap: .6rem;
      grid-column-gap: .6rem;
      position: absolute;
      display: grid;
      bottom: 13.8%;
      width: 70%;
      left: 16.2%;
      height: 18%;
    }

    .prop {
      mix-blend-mode: multiply;
      height: 100%;
      font-family: $cinzel;
      color: $romanCoffee;
      font-size: .6rem;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;

      .value {
        font-weight: 800;
        font-size: .7rem;
        margin-left: .3rem;
      }

      .prop-icon {
        background-size: 100% auto;
        background-position: center;
        background-repeat: no-repeat;
        width: .9rem;
        height: .9rem;
        content: '';

        &.bg {
          background-image: url("../assets/images/interface/bg.png");
        }

        &.mouth {
          background-image: url("../assets/images/interface/mouth.png");
        }

        &.clothes {
          background-image: url("../assets/images/interface/clothes.png");
        }

        &.eyes {
          background-image: url("../assets/images/interface/eyes.png");
        }

        &.skin {
          background-image: url("../assets/images/interface/skin.png");
        }

        &.weapon {
          background-image: url("../assets/images/interface/weapon.png");
        }
      }

    }

    .single-prop {
      position: absolute;
      bottom: 18.5%;
      left: 51%;
      transform: translateX(-50%);
      width: 100%;
      height: 100%;
      max-height: 2.3rem;
      max-width: 5.1rem;

      .prop {
        width: 100%;
        height: 100%;

        .value {
          font-size: 1.2rem;
          line-height: 1.4rem;
        }

        .prop-icon {
          width: 1.4rem;
          height: 1.4rem;
        }
      }
    }

    .star {
      height: 1.8rem;
      width: 1.8rem;
      content: '';
      position: absolute;
      right: -22%;
      top: -50%;
      background-image: url("../assets/images/interface/star.png");
      background-position: center;
      background-size: 100% auto;
      background-repeat: no-repeat;
    }

    .rare {
      mix-blend-mode: multiply;
      color: $romanCoffee;
      font-size: .7rem;
      position: absolute;
      text-align: center;
      font-family: $cinzel;
      line-height: .8rem;
      letter-spacing: -.01rem;
      font-weight: 700;
      bottom: 6%;
      right: 25%;
      left: 29%;
    }

  }

  &.with-zoom {
    transition: all .2s;
    position: relative;

    &:hover {
      transform: scale(1.5) !important;
      z-index: 10;
    }

  }

}
