@import "../../assets/styles/variables";

.match-history {
  display: flex;
  flex-direction: column;
  align-items: center;

  .round {
    font-size: 1rem;
    color: $mortum;
    font-family: $cinzel;
    font-weight: bold;
    line-height: 1.3rem;
    height: 3.25rem;
    width: 6.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url('../../assets/images/interface/round.png');
    background-size: 100% auto;
    background-position: center;
    background-repeat: no-repeat;
  }

  .win {
    font-size: 1.6rem;
    margin-top: 1.6rem;
    font-family: $cinzel;
    font-weight: bold;
    color:$mortum;
  }

  .arena {
    margin-top: 1.7rem;

    .card {
      margin-top: 0;
    }

    .playing-cards {
      position: relative;
    }
  }
}
