@import "../assets/styles/variables";

#playground .button {
  border-radius: .2rem;
  display: flex;
  width: 100%;
  box-sizing: border-box;
  justify-content: center;
  cursor: pointer;
  font-family: $hotelTransylvania;
  font-size: 2.4rem;
  text-align: center;
  letter-spacing: 0.02em;
  color: $white;
  padding: 1.2rem 2.8rem;
  text-transform: uppercase;
  position: relative;
  //transition: .3s color ease-in-out;
  text-decoration: none;
  font-weight: 700;
  user-select: none;

  &:hover {
    color: $white;
  }

  &.disabled {
    pointer-events: none;
  }

  &.fancy {
    min-width: 28.4rem;
    letter-spacing: 0;
    font-weight: 400;
    box-shadow: none;
    width: 28.4rem;
    height: 6.7rem;
    padding: 0;

    & > span:first-child {
      justify-content: center;
      align-items: center;
      padding-left: .2rem;
      padding-top: .4rem;
      display: flex;
      width: 100%;
    }

  }

  &.outline, &.simple, &.base {
    justify-content: center;
    align-items: center;
    font-weight: 400;
    width: 25.2rem;
    height: 6.8rem;
    padding: .2rem 0 0 .2rem;
    display: flex;
  }

  &.blue {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    width: 35.35rem;
    height: 8.4rem;
    padding: .2rem 0 0 .2rem;
    font-size: 5rem;
    color: $bossanova;
  }

  &.simple {
    color: $mortum;
  }

}
