@import "../../assets/styles/variables";

#match-results {
  display: flex;
  padding: 1.6rem;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  .results-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 133rem;
    height: 100%;
    max-height: 76.8rem;
    position: relative;

    .match-cards {
      position: absolute;
      top: 17.8rem;
      left: 50%;
      transform: translateX(-50%);
    }

    .arena {
      .card {
        height: auto;

        & + .card {
          margin-left: 2.4rem;
        }

      }

    }

    .match-info {
      color: $white;
      display: flex;
      flex-direction: column;
      align-items: center;
      position: absolute;
      bottom: 7rem;
      left: 50%;
      transform: translateX(-50%);

      p {
        font-size: 2.4rem;
        margin: 0 0 .2rem;
        color: $white;
        font-family: $condensed;
        text-align: center;

        &.round {
          text-transform: uppercase;
          color: $tamarillo;
        }

      }

      .message {
        max-width: 30rem;
      }

    }

    .next-round {
      position: absolute;
      bottom: 2.1rem;
      left: 50%;
      transform: translateX(-50%);
      font-size: 2.4rem;
      color: $white;
      font-family: $condensed;
      cursor: pointer;
    }

  }

}
