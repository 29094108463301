@import "../assets/styles/variables";

#rooms {
  font-size: 1.6rem;
  color: $white;
  width: 100%;
  height: 100%;
  padding: 1.6rem;
  display: flex;
  align-items: center;
  justify-content: center;

  .frame {
    justify-content: center;
    align-items: center;
    flex-direction: column;
    display: flex;
    height: 100%;
    width: 100%;
    max-width: 133rem;
    max-height: 76.8rem;
    background-position: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-image: url("../assets/images/interface/bg-frame-wide.png");
    position: relative;
  }

  h1 {
    font-size: 9rem;
    line-height: 10.3rem;
    margin: 0;
    font-family: $hotelTransylvania;
    letter-spacing: .5rem;
    text-shadow: 0 .2rem 0 rgba($citrineWhite, .25);
    color: $bossanova;
    font-weight: 400;
  }

  .connecting, .starting {
    margin-top: 5.6rem;
    font-size: 1.6rem;
    line-height: 2.1rem;
    font-family: $cinzel;
    color: $romantic;

    .text {
      text-align: center;
    }

    .button {
      margin-top: 3rem;
    }
  }

  .footer {
    position: absolute;
    font-size: 1.4rem;
    color: rgba($bridalHeath, .6);
    text-align: center;
    bottom: 4rem;
    right: 0;
    left: 0;
  }
}
