/*
 * Media query breakpoints
 */

$minMobileMdWidth: 375px;
$minTabletWidth: 768px;
$minDesktopSmallWidth: 992px;
$minDesktopLargeWidth: 1260px;
$minDesktopLargeHeight: 720px;
$minDesktopWideWidth: 1660px;
$minDesktopUltraWideWidth: 1921px;

$maxMobileSmWidth: 359.98px;
$maxMobileWidth: 767.98px;
$maxTabletWidth: 991.98px;
$maxDesktopSmallWidth: 1259.98px;
$maxDesktopLargeWidth: 1659.98px;

/**
 * Fonts
 */

$hotelTransylvania: 'hosteltransilvania', serif;
$cinzel: 'Cinzel', serif;
$condensed: 'din_condensed';

/**
 * Colors
 */

$persianRed: #D42D2D;
$darkOlive: #556D21;
$mortum: #66332E;
$coffee: #775545;
$russianViolet: #1B0B33;
$tamarillo: #A41A1A;
$fuzzyWuzzyBrown: #bf614d;
$diSerria: #d9a066;
$cocoaBean: #421f1d;
$cocoaBrown: #2C1C22;
$white: #fff;
$woodyBrown: #452D36;
$lotus: #7f3d35;
$aubergine: #270914;
$bossanova: #492E63;
$tulipTree: #EDA34E;
$citrineWhite: #FBF7DA;
$bridalHeath: #FFFBF3;
$romanCoffee: #774E46;
$starkWhite: #E9D4C3;
$violet: #1B0B33;
$pomegranate: #F43B3B;
$contessa: #C17459;
$copperRose: #916b64;
$dustStorm: #E1C6BF;
$sepiaSkin: #A55942;
$jacarta: #3B2C60;
$romantic: #FFD5B7;