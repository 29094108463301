/*
 * Media query
 */

@mixin mediaQueryMin( $minWidth ) {
  @media only screen and (min-width: $minWidth) {
    @content;
  }
}

@mixin mediaQueryMax( $maxWidth ) {
  @media only screen and (max-width: $maxWidth) {
    @content;
  }
}

@mixin mediaQueryMaxHeight( $maxHeight ) {
  @media only screen and (max-height: $maxHeight) {
    @content;
  }
}

@mixin mediaQueryBetween( $minWidth, $maxWidth ) {
  @media only screen and (min-width: $minWidth) and (max-width: $maxWidth) {
    @content;
  }
}

/*
 * Min
 */

@mixin minMobileMd {
  @include mediaQueryMin( $minMobileMdWidth ) {
    @content;
  }
}

@mixin minTablet {
  @include mediaQueryMin( $minTabletWidth ) {
    @content;
  }
}

@mixin minDesktopSmall {
  @include mediaQueryMin( $minDesktopSmallWidth ) {
    @content;
  }
}

@mixin minDesktopLarge {
  @include mediaQueryMin( $minDesktopLargeWidth ) {
    @content;
  }
}

@mixin minDesktopLargeHeight {
  @include mediaQueryMaxHeight( $minDesktopLargeHeight ) {
    @content;
  }
}

@mixin minDesktopWide {
  @include mediaQueryMin($minDesktopWideWidth) {
    @content;
  }
}

@mixin minDesktopUltraWide {
  @include mediaQueryMin($minDesktopUltraWideWidth) {
    @content;
  }
}

/*
 * Max
 */

@mixin maxMobile {
  @include mediaQueryMax( $maxMobileWidth ) {
    @content;
  }
}

@mixin maxTablet {
  @include mediaQueryMax( $maxTabletWidth ) {
    @content;
  }
}

@mixin maxDesktopSmall {
  @include mediaQueryMax( $maxDesktopSmallWidth ) {
    @content;
  }
}

/*
 * Only
 */

@mixin onlyTablet {
  @include mediaQueryBetween( $minTabletWidth, $maxTabletWidth ) {
    @content;
  }
}

@mixin placeholderColor {
  &::-webkit-input-placeholder {
    @content;
  }
  &:-moz-placeholder {
    @content;
  }
  &::-moz-placeholder {
    @content;
  }
  &:-ms-input-placeholder {
    @content;
  }
}
