@import "../../assets/styles/variables";
@import "../../assets/styles/fonts";

.match-container {
  width: 100%;
  height: 100%;
  padding: 1.6rem;
  display: flex;
  align-items: center;
  justify-content: center;

  .match-inner {
    width: 100%;
    height: 100%;
    max-width: 133rem;
    max-height: 76.8rem;
    margin: 0 auto;
    position: relative;

    .clock {
      display: flex;
      height: 16.2rem;
      width: 15.4rem;
      content: '';
      position: absolute;
      background-size: 100% auto;
      background-image: url("../../assets/images/interface/clock.png");
      background-position: center;
      background-repeat: no-repeat;
      top: 3.4rem;
      left: 50%;
      transform: translateX(-47%);
      align-items: center;
      justify-content: center;

      @keyframes rota {
        0% {
          transform: translateX(-50%) rotate(0deg);
        }
        100% {
          transform: translateX(-50%) rotate(360deg)
        }
      }

      .clock-inner {
        position: relative;

        .clock-tick {
          height: 5.1rem;
          width: 3rem;
          content: '';
          background-size: 100% auto;
          background-image: url("../../assets/images/interface/clock-tick.png");
          background-position: center;
          background-repeat: no-repeat;
          position: absolute;
          left: 50%;
          top: -4.2rem;
          transform-origin: 1.5rem 3.7rem;
          transform: translateX(-50%);
          animation: rota var(--duration) linear forwards;
        }
      }
    }

    .current-player {
      display: flex;
      width: 100%;
      max-width: 96rem;
      position: absolute;
      justify-content: center;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-45%);

      .card {
        margin-right: 3rem;

        &:last-of-type {
          margin-right: 0;
        }
      }
    }

    .info {
      position: absolute;
      bottom: 0;
      left: 50%;
      width: 100%;
      max-width: 46rem;
      height: 12.3rem;
      transform: translateX(-50%);
      background-position: center;
      background-size: 100% 100%;
      background-repeat: no-repeat;
      background-image: url("../../assets/images/interface/match-info.png");
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 2rem 0 2.6rem;

      .round {
        color: $mortum;
        text-transform: uppercase;
        font-size: 2rem;
        line-height: 2.2rem;
        font-family: $condensed;
        letter-spacing: 0.1rem;
      }

      .info-text {
        font-size: 2.4rem;
        color: $white;
        font-family: $condensed;
        line-height: 2.6rem;
        margin-top: .3rem;
      }
    }
  }
}
