@import "../../assets/styles/variables";

#game-results {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 1.6rem;

  .game-results-container {
    width: 100%;
    max-width: 133rem;
    height: 100%;
    max-height: 76.8rem;
    position: relative;
  }

  .results {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 108rem;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 13rem;
  }

  .play-again {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 6.5rem;
  }

  .home {
    position: absolute;
    left: 5.85rem;
    bottom: 9.2rem;
    width: 19rem!important;
    height: 5.05rem!important;
  }

  .leaderboard {
    position: absolute;
    right: 7.7rem;
    bottom: 9.2rem;
    width: 19rem!important;
    height: 5.05rem!important;
  }

  .winner {
    font-size: 2rem;
    line-height: 2.4rem;
    font-family: $condensed;
    color: $white;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 2.17rem;
  }
}
