@import "../../assets/styles/variables";

:root {
  --wheel-font: 'Lato', 'Quicksand', sans-serif;
  --wheel-size: 45.5rem;
  --wheel-slice-spacing: 2rem;
  --wheel-border-size: .5rem;
  --accent-color1: #{$copperRose};
  --accent-color2: #{$dustStorm};
  --text-color: #{$violet};
  --PI: 3.14159265358979;
  --nb-item: 0;
  --item-nb: 0;
  --reset-duration: 0.25s;
  --spinning-duration: 5s;
  --rotation: 0deg;
}

.wheel-outer {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.6rem;

  .wheel-inner {
    width: 100%;
    height: 100%;
    max-width: 133rem;
    max-height: 76.8rem;
    position: relative;
  }

  .wheel-container {
    display: flex;
    align-items: center;
    position: absolute;
    box-sizing: content-box;
    width: 100%;
    height: 100%;
    max-width: calc(var(--wheel-size) + 2 * var(--wheel-border-size));
    max-height: calc(var(--wheel-size) + 2 * var(--wheel-border-size));
    padding: 3.1rem;
    border-radius: 50%;
    background-position: center;
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-image: url("../../assets/images/interface/wheel-frame.png");
    user-select: none;
    top: 2.5rem;
    left: 50%;
    transform: translateX(-50%);

    .wheel-arrow {
      width: 3.2rem;
      content: '';
      height: 3.6rem;
      background-position: center;
      background-size: 100% auto;
      background-repeat: no-repeat;
      background-image: url("../../assets/images/interface/wheel-arrow.png");
      position: absolute;
      top: 3.2rem;
      left: 50%;
      transform: translateX(-50%);
      z-index: 12;
    }

    .wheel-cards {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);
      z-index: 5;

      .playing-cards {
        justify-content: space-between;
        position: relative;

        .card {
          height: auto;
          margin: 0;
        }
      }
    }

    .wheel {
      display: block;
      position: relative;
      box-sizing: content-box;
      margin: auto;
      width: var(--wheel-size);
      height: var(--wheel-size);
      overflow: hidden;
      border-radius: 50%;
      background-color: var(--accent-color1);
      transition: transform var(--reset-duration);
      transform: rotate(0deg);
      border: .3rem solid $violet;

      &.spinning {
        transition: transform var(--spinning-duration) cubic-bezier(.25, 0, .40, 1);
        transform: rotate(var(--rotation))
      }

      &::after {
        display: block;
        position: absolute;
        content: '';
        background-color: var(--accent-color2);
        width: 2.5rem;
        height: 2.5rem;
        z-index: 2;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 50%;
      }

      .wheel-item {
        display: block;
        position: absolute;
        box-sizing: border-box;
        top: 50%;
        left: 50%;
        width: 50%;
        transform-origin: left;
        transform: translateY(-50%) rotate(calc(var(--item-nb) * (360deg / 7)));
        color: var(--text-color);
        text-align: right;
        padding: 0 2.5rem 0 5rem;
        font-family: var(--wheel-font);
        font-size: 1.8rem;

        &::before {
          content: ' ';
          display: block;
          position: absolute;
          box-sizing: border-box;
          z-index: -1;
          width: 0;
          height: 0;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          padding-left: 0;
          border-radius: 0;
          --slice-max-width: calc(var(--PI) * var(--wheel-size) + var(--wheel-size) / 2);
          --slice-width: calc((var(--slice-max-width) / var(--nb-item)) - var(--wheel-slice-spacing));
          border: solid transparent calc(var(--slice-width) / 2);
          border-left: solid transparent 0;
          border-right: solid var(--accent-color2) calc(var(--wheel-size) / 2);
        }

        &.emoji {
          font-size: 2.4rem;
        }

      }

    }
  }

  .wheel-button {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 6.8rem;
    height: 8.1rem;

    .wheel-message {
      p {
        font-size: 2.4rem;
        line-height: 2.6rem;
        font-family: $condensed;
        margin: 0 0 .2rem;
        color: $white;
        text-align: center;

        &:first-of-type {
          font-size: 2rem;
          line-height: 2.2rem;
          color: $tamarillo;
          text-transform: uppercase;
        }
      }
    }
  }

  .wheel-status {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 2.1rem;
    font-size: 2rem;
    line-height: 2.4rem;
    color: $white;
    font-family: $condensed;
  }
}
