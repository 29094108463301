@import 'src/assets/styles/variables';

.deck {
  width: 100%;
  height: 6rem;
  max-width: 18.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-left: 3.1rem;
  margin-bottom: .8rem;
  border: none;
  outline: none;

  .deck-name {
    font-size: 2rem;
    line-height: 2.4rem;
    color: $mortum;
    font-family: $hotelTransylvania;
    text-transform: uppercase;
    font-weight: 700;
  }

  .delete-deck {
    font-family: $hotelTransylvania;
    color: $white;
    text-transform: uppercase;
    background: $persianRed;
    position: absolute;
    bottom: 2rem;
    right: 0;
    padding: .4rem;
  }

  &.new {
    font-size: 2.4rem;
    color: $white;
    text-transform: uppercase;
    font-family: $hotelTransylvania;
    font-weight: 700;
    height: 6.2rem;
  }
}
