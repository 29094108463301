@import "../../assets/styles/variables.scss";

#leaderboard {
  background: rgba(#000, .5);
  justify-content: center;
  align-items: center;
  position: fixed;
  display: flex;
  z-index: 100;
  bottom: 0;
  right: 0;
  left: 0;
  top: 0;

  .inner {
    background: $white;
    width: 100%;
    height: 100%;
    max-width: 49rem;
    max-height: 58.4rem;
    position: relative;


    h2 {
      position: absolute;
      font-family: $hotelTransylvania;
      font-size: 4.8rem;
      line-height: 5.5rem;
      color: $jacarta;
      top: 9.6rem;
      margin: 0;
      left: 50%;
      min-width: max-content;
      transform: translateX(-50%);
    }

    .close {
      height: 7.42rem;
      width: 7.42rem;
      position: absolute;
      content: '';
      background-image: url("../../assets/images/interface/close.png");
      background-position: center;
      background-size: 100% auto;
      background-repeat: no-repeat;
      border-radius: 50%;
      top: 14.7rem;
      right: -1rem;
      cursor: pointer;
    }

    .leaderboard-content {
      height: 100%;
      max-height: 31.4rem;
      width: 100%;
      max-width: 40.1rem;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: 21.8rem;
      padding: 2.08rem 2.06rem;

      h1 {
        margin: 0;
        font-size: 4rem;
        line-height: 4.6rem;
        font-family: $hotelTransylvania;
        color: $jacarta;
        text-align: center;
      }

      .player, .current-player {
        font-size: 2.8rem;
        line-height: 3rem;
        color: $white;
        font-family: $condensed;

        span {
          color: $jacarta;
        }
      }

      .players {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        width: 100%;
        height: 100%;
        max-height: 16.8rem;
        margin-top: 3rem;

        .player {
          width: 100%;
          max-width: 17rem;
          margin-bottom: .4rem;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;

          &:nth-child(5n) {
            margin-bottom: 0;
          }
        }

      }

      .current-player {
        position: absolute;
        bottom: -1.6rem;
        left: 50%;
        transform: translateX(-50%);
      }
    }

  }

}
