@import url('https://fonts.googleapis.com/css2?family=Cinzel:wght@500;700;800&display=swap');

@font-face {
  font-family: 'phantom_fingersregular';
  src: url('../fonts/phantom_fingers-webfont.woff2') format('woff2'),
  url('../fonts/phantom_fingers-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'hosteltransilvania';
  src: url('../fonts/hosteltransilvania.woff2') format('woff2'),
  url('../fonts/hosteltransilvania.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'din_condensed';
  src: url('../fonts/DINCondensed.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}