@import "assets/styles/fonts";
@import "assets/styles/variables";
@import "assets/styles/mixins";

html,
body,
#root {
  overflow: hidden;
  height: 100%;
}

* {
  box-sizing: border-box;
}

html {
  font-size: 10px;

  @include minDesktopLargeHeight {
    font-size: 9.2px;
  }

}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}
