@import "../assets/styles/variables";

#playground {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  display: flex;
  height: 100%;

  .arena {
    flex-direction: column;
    align-items: center;
    flex-wrap: nowrap;
    display: flex;
    flex: 1;

    .playing-cards {
      align-items: center;
      display: flex;
    }

    .vs {
      position: absolute;
      left: 50%;
      z-index: 6;
      transform: translateX(-50%);
      background-position: center;
      background-size: 100% auto;
      background-repeat: no-repeat;
      background-image: url("../assets/images/interface/vs.png");
      height: 5.8rem;
      width: 5.8rem;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: $hotelTransylvania;
      font-size: 3.8rem;
      color: $white;
    }

  }

  .info {
    margin-top: 5rem;
    color: $white;
    font-size: 1.6rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    .info-title {
      color:$russianViolet;
      text-transform: uppercase;
      font-weight: bold;
    }

    p {
      margin: 1.6rem 0 0;
    }

  }

}
