@import 'src/assets/styles/variables';
@import 'src/assets/styles/mixins';

.collection-tooltip {
  font-size: 1.4rem;

  h1 {
    color: $jacarta;
  }
}


#my-collection {
  width: 100vw;
  height: 100vh;
  content: '';
  display: flex;
  align-items: center;
  justify-content: center;

  .inner {
    height: 100%;
    width: 100%;
    max-width: 142rem;
    max-height: 76.8rem;
    margin: 0 auto;
    display: flex;
  }

  h2 {
    font-size: 3.2rem;
    line-height: 4.2rem;
    font-family: $hotelTransylvania;
    margin: 0;
  }

  .cards-container {
    box-sizing: content-box;
    width: 100%;
    padding: 2rem 1.4rem 4.4rem 2.6rem;
    margin: 1.6rem 0 1.6rem 1.6rem;
    max-width: 108rem;
    background-position: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-image: url("../../assets/images/interface/bg-frame.png");
    align-items: center;
    display: flex;

    .collection {
      width: 100%;
      max-width: 104rem;
      height: 100%;
      max-height: 70rem;
      display: flex;
      flex-direction: column;
      padding: 4.1rem 3.7rem 5.6rem 4.2rem;
      position: relative;

      .collection-header {
        display: flex;
        align-items: center;
        width: 100%;

        h2 {
          color: $coffee;
          font-size: 4.8rem;
          line-height: 5.6rem;
        }

        .total-players {
          position: absolute;
          display: flex;
          right: 8rem;
          font-family: $condensed;
          color: $coffee;
          font-size: 2.4rem;
          background: $starkWhite;
          padding: 1rem;
        }

        .top-line {
          margin-left: 4rem;
          width: 100%;
          height: .5rem;
          background-size: 100% 100%;
          background-repeat: no-repeat;
          background-image: url("../../assets/images/interface/line.png");
        }

      }

      .cards {
        margin-top: .8rem;
        display: grid;
        width: 100%;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        justify-content: flex-start;
        grid-column-gap: 2.4rem;
        grid-row-gap: 2.4rem;

        @include minDesktopWide {
          grid-row-gap: 1.6rem;
        }

      }

      .skull-line {
        height: 3.6rem;
        width: 100%;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-image: url("../../assets/images/interface/skull-line.png");
        margin-top: 2rem;
      }

      .buttons {
        display: flex;
        align-items: center;
        position: absolute;
        bottom: -3rem;
        left: 50%;
        width: 100%;
        padding: 0 3.7rem 0 4.2rem;
        transform: translateX(-50%);

        .nav-btn {
          width: 6.4rem;
          height: 6.4rem;
          background-size: 100% 100%;
          background-repeat: no-repeat;
          cursor: pointer;
          border: none;
          outline: none;

          &.prev {
            transform: scaleX(-1);
          }

          &.next {
            margin-left: 2.4rem;
          }

        }

        .leaderboard-btn {
          margin-left: auto;
        }

      }

    }

  }

  .decks-container {
    box-sizing: border-box;
    width: 100%;
    max-width: 23.3rem;
    height: 100%;
    max-height: 72rem;
    display: flex;
    flex-direction: column;
    margin: 2.4rem 2.4rem 0;
    background-image: url("../../assets/images/interface/decks-bg.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;

    h2 {
      color: $white;
      text-align: center;
      margin-top: 3rem;
      font-size: 3.4rem;
      line-height: 4.6rem;
    }

    .decks {
      overflow-y: auto;
      height: 100%;
      margin-top: 1.6rem;
      max-height: 51.2rem;

      .no-decks {
        margin-left: 3.3rem;
        font-family: $condensed;
        color: $white;
        font-size: 2.4rem;
        line-height: 2.6rem;
        text-align: center;
        max-width: 18rem;
        margin-bottom: 2rem;
      }
    }

    .play {
      display: flex;
      margin-top: auto;
      height: 8.5rem;
      cursor: pointer;
      width: 100%;
      max-width: 12.8rem;
      align-items: center;
      justify-content: center;
      margin-left: 5.8rem;
      margin-bottom: 2.9rem;
      border: none;
      outline: none;
      font-size: 5rem;
      line-height: 5.7rem;
      font-family: $hotelTransylvania;
      text-transform: uppercase;
      color: $darkOlive;
    }

  }

  #get-leaderboard {
    padding: 1.2rem 1.6rem;
    background: orange;
    position: absolute;
    display: flex;
    z-index: 100;
    bottom: 0;
    left: 0;
  }

  .disabled {
    pointer-events: none;
  }

}
