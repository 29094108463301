@import "src/assets/styles/variables";
@import "src/assets/styles/mixins";

#welcome {
  justify-content: center;
  flex-direction: column;
  position: relative;
  display: flex;
  color: $white;
  width: 100%;

  .welcome-message {
    text-shadow: 0 .2rem 0 rgba($citrineWhite, .25);
    font-family: $hotelTransylvania;
    color: $bossanova;
    font-size: 6.4rem;
    text-align: center;
    margin-bottom: 5.6rem;
  }

  .logo {
    text-shadow: 0 .2rem 0 rgba($citrineWhite, .25);
    font-family: $hotelTransylvania;
    justify-content: center;
    color: $bossanova;
    letter-spacing: .5rem;
    font-weight: 400;
    font-size: 9rem;
    margin-bottom: 5.6rem;
    position: relative;
    display: flex;

    &.logo-with-paragraph,
    &.logo-small {
      font-size: 6.4rem;
    }

    &.logo-with-paragraph {
      margin-bottom: 2.4rem;

    }

    img {
      height: 10.4rem;
      width: auto;
    }

  }

  .welcome-content {
    justify-content: center;
    display: flex;
  }

  .button {
    width: max-content;
  }

  p {
    font-family: $cinzel;
    text-align: center;
    margin: 0 0 4rem 0;
    font-size: 1.6rem;
    color: $romantic;
  }

  h3 {
    font-size: 3rem;
    margin: 0 0 3rem;
  }

  .username-input {
    display: flex;
    flex-direction: column;
    align-items: center;

    input {
      margin-bottom: .8rem;
      transition: all .2s;
      height: 6.6rem;
      width: 25rem;
      font-size: 2.4rem;
      text-align: center;
      outline: none;
      border: 0;
      color: $white;
      border-radius: 0;
      padding: 0 2.4rem;
      font-family: $hotelTransylvania;
      text-transform: uppercase;

      @include placeholderColor {
        color: rgba($white, .5)
      }

    }

    .button {
      width: 100%;
    }

  }

  .getting-data {
    font-size: 1.6rem;
    line-height: 2.1rem;
    font-family: $cinzel;
    color: $romantic;
  }

  .welcome-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;

    .buy {
      flex-direction: column;
      align-items: center;
      display: flex;

      .button {

        & + .button {
          margin-top: .8rem;
        }

      }

    }

  }

  .welcome-footer {
    position: absolute;
    font-size: 1.4rem;
    color: rgba($bridalHeath, .6);
    text-align: center;
    bottom: 4rem;
    right: 0;
    left: 0;
  }

}
